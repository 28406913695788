import * as React from "react"
import { FC, useContext, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { GiftListContext } from "../../../context/GiftListProvider"
import ProductSelectionType from "../../gift/choice/ProductSelectionType"
import SidebarChoiceItem from "./SidebarChoiceItem"
import { usePrevious } from "../../../hooks/usePrevious"
import { lockScroll } from "../../../util/generalUtil"
import { FullLinkButton, PrimaryButton } from "@social-supermarket/social-supermarket-components"
import { colors, formatPrice, parsePrice } from "@social-supermarket/social-supermarket-model"

const Container = styled.div`
  width: 300px;
  position: fixed;

  right: ${({ visible, forceFullyVisible }) =>
    forceFullyVisible ? "0" : visible ? "-240px" : "-300px"};
  top: 100px;
  transition: all 0.2s ease-in-out;
  border-left: 1px solid ${colors.sidebarBorderGrey};
  background-color: white;
  height: calc(100vh - 93px);
  display: flex;
  flex-direction: column;

  &:hover {
    right: ${({ visible }) => (visible ? "0" : "-300px")};
  }
`

const Items = styled.div`
  flex: 1;
  overflow: auto;
`
const Toolbar = styled.div`
  padding: 0px 10px 15px 10px;

  display: flex;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  &&& {
    flex: 1;
    margin-right: 5px;
  }
`
const StyledFullLinkButton = styled(FullLinkButton)`
  margin-left: 5px;
`
const Meta = styled.div`
  padding: 10px 10px 5px 10px;
  display: flex;
  font-size: 0.8em;
  border-top: 1px solid ${colors.sidebarBorderGrey};
  justify-content: space-between;
`

const Title = styled.div`
  padding: 5px;
  font-weight: bold;
  border-bottom: 1px solid ${colors.sidebarBorderGrey};
`

interface Props {
  onSubmit: () => void
  onCancel: () => void
  submitLabel: string
}

const GiftListSidebar: FC<Props> = ({ onSubmit, onCancel, submitLabel }) => {
  const { choices, removeChoice, cancel } = useContext(GiftListContext)
  const prevChoices = usePrevious({ choices })
  const [forceFullyVisible, setForceFullyVisible] = useState<boolean>(false)
  const ref = useRef(null)

  const min = formatPrice(getMinPrice(choices))
  const max = formatPrice(getMaxPrice(choices))
  const avg = formatPrice(getAvgPrice(choices))

  useEffect(() => {
    if ((!prevChoices?.choices || prevChoices?.choices?.length === 0) && choices.length > 0) {
      setForceFullyVisible(true)
      setTimeout(() => {
        setForceFullyVisible(false)
      }, 1000)
    }

    if (prevChoices?.choices?.length < choices.length) {
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: "smooth" })
      }, 100)
    }
  }, [choices])

  const handleEnter = () => {
    lockScroll(true)
  }
  const handleLeave = () => {
    lockScroll(false)
  }

  return (
    <Container
      visible={choices.length > 0}
      forceFullyVisible={forceFullyVisible}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
    >
      <Title>Gift Page Options</Title>
      <Items>
        {choices.map(choice => (
          <SidebarChoiceItem
            selection={choice}
            key={`${choice.product.productId}-${choice.variant?.variantId}`}
          />
        ))}
        <div ref={ref} />
      </Items>
      <Meta>
        <div>Average: {avg}</div>{" "}
        <div>
          {min} - {max}
        </div>
      </Meta>
      <Toolbar>
        <StyledPrimaryButton slim fullWidth dark onClick={onSubmit}>
          {submitLabel}
        </StyledPrimaryButton>
        <StyledFullLinkButton slim onClick={onCancel}>
          Cancel
        </StyledFullLinkButton>
      </Toolbar>
    </Container>
  )
}

const getMinPrice = (selections: ProductSelectionType[]) => {
  let min = 999999
  selections.forEach(selection => {
    const price = selection.variant
      ? parsePrice(selection.variant.price)
      : parsePrice(selection.product.price)
    if (price < min) {
      min = price
    }
  })

  return min === 999999 ? 0 : min
}
const getMaxPrice = (selections: ProductSelectionType[]) => {
  let max = 0
  selections.forEach(selection => {
    const price = selection.variant
      ? parsePrice(selection.variant.price)
      : parsePrice(selection.product.price)
    if (price > max) {
      max = price
    }
  })

  return max
}

const getAvgPrice = (selections: ProductSelectionType[]) => {
  let total = 0
  selections.forEach(selection => {
    const price = selection.variant
      ? parsePrice(selection.variant.price)
      : parsePrice(selection.product.price)

    total += price
  })

  return total / selections.length
}

export default GiftListSidebar
